import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../store/Users/action';
import { getBadges, assignBadge } from '../../store/Badges/action';
import 'antd/dist/antd.css';
import { Table } from 'antd';
import { Form, Button } from 'react-bootstrap';
import UiModalPopup from '../../components/UiModalPopup/index';
import { Card, CardContent } from '@material-ui/core/';
import './Badges.css';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { Spin, Alert, Space, Input } from 'antd';
import Swal from 'sweetalert2';

let baseURL = 'https://dev.codiclust.de/uploads/';

const AssignBadge = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    show: false,
    record: '',
    searchText: '',
    searchedColumn: '',
  });
  useEffect(() => {
    dispatch(getUsers());
    dispatch(getBadges());
  }, []);
  const tableData = useSelector((state) => state.Users.users);
  const badgesData = useSelector((state) => state.badges.badgesList.data);
  const handleAssign = (data) => {
    const obj = {
      userId: state.record._id,
      badgeId: data._id,
    };
    console.log("i am sending", obj)
    dispatch(assignBadge(obj));
    Swal.fire({
      title: 'Success',
      text: ' Assigned badge successfully',
      icon: 'success',
      timer: 3000,
    });
    setTimeout(() => {
      dispatch(getUsers());
    }, 2000);
    setState({ ...state, show: false });
  };
  let textInput = null;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            textInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: '' });
  };
  let usersColumns = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      ...getColumnSearchProps('displayName'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'current badgeName',
      dataIndex: 'assignedBadges',
      key: 'assignedBadges',
      render: (text) => (text && text.length > 0 ? text && text[0].badgeName : '-'),
    },
    {
      title: 'current badge',
      dataIndex: 'assignedBadges',
      key: 'assignedBadges',
      render: (text) =>
        text && text.length > 0 ? (
          <img
            style={{ width: '102px', height: '52px', cursor: 'pointer' }}
            src={baseURL + text[0].image}
            alt=""
          />
        ) : (
          '-'
        ),
    },
    {
      title: 'Action',
      key: 'status',
      render: (text, record) => (
        <div>
          <label
            style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              setState({ ...state, show: true, record: record });
            }}
          >
            AssignBadge
          </label>
        </div>
      ),
    },
  ];
  return (
    <div>
      {tableData && !tableData.success ? (
        <Spin style={{ marginTop: '40vh', marginLeft: '7vw' }} tip="Loading...">
          <Alert />
        </Spin>
      ) : (
        <Table
          style={{
            paddingRight: '1px',
            marginBottom: '20px',
            border: '1px solid lightgray',
            width: '80.3%',
            marginLeft: '241px',
            marginTop: '35px',
          }}
          dataSource={tableData && tableData.data}
          columns={usersColumns}
          pagination={{ defaultPageSize: 15 }}
        />
      )}
      <UiModalPopup
        title={'Assign Badge'}
        showSave={false}
        show={state.show}
        onClose={() => {
          setState({ ...state, show: false });
        }}
      >
        <div>
          {badgesData &&
            badgesData.map((data) => (
              <Card style={{ marginBottom: '25px', background: '#9e9e9e47' }}>
                <CardContent>
                  <img className="commentPic" alt="" src={baseURL + data.image}></img>
                  <span className="commentHead">
                    <b>{data.badgeName}</b>
                  </span>
                  <div>
                    <p className="commentContent">{data.badgeDescription}</p>
                    <Button style={{ float: 'right' }} onClick={() => handleAssign(data)}>
                      Assign
                    </Button>
                  </div>
                </CardContent>
              </Card>
            ))}
        </div>
      </UiModalPopup>
    </div>
  );
};
export default AssignBadge;
